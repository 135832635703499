import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true
          }
        ]
      }
    },
    // TABLES START
    {
      path: '/tables/language',
      name: 'table-language',
      component: () => import('@/views/tables/Language.vue'),
      meta: {
        pageTitle: 'Language Table',
        breadcrumb: [
          {
            text: 'Language Table',
            active: true
          }
        ]
      }
    },
    {
      path: '/tables/currency',
      name: 'table-currency',
      component: () => import('@/views/tables/Currency.vue'),
      meta: {
        pageTitle: 'Currency Table',
        breadcrumb: [
          {
            text: 'Currency Table',
            active: true
          }
        ]
      }
    },
    {
      path: '/tables/firm',
      name: 'table-firm',
      component: () => import('@/views/tables/Firm.vue'),
      meta: {
        pageTitle: 'Firm Table',
        breadcrumb: [
          {
            text: 'Firm Table',
            active: true
          }
        ]
      }
    },
    {
      path: '/tables/machine',
      name: 'table-machine',
      component: () => import('@/views/tables/Machine.vue'),
      meta: {
        pageTitle: 'Machine Table',
        breadcrumb: [
          {
            text: 'Machine Table',
            active: true
          }
        ]
      }
    },
    {
      path: '/tables/machine-group',
      name: 'table-machine-group',
      component: () => import('@/views/tables/MachineGroup.vue'),
      meta: {
        pageTitle: 'Machine Group Table',
        breadcrumb: [
          {
            text: 'Machine Group Table',
            active: true
          }
        ]
      }
    },
    {
      path: '/tables/material-group',
      name: 'table-material-group',
      component: () => import('@/views/tables/MaterialGroup.vue'),
      meta: {
        pageTitle: 'Material Group Table',
        breadcrumb: [
          {
            text: 'Material Group Table',
            active: true
          }
        ]
      }
    },
    {
      path: '/tables/personnel',
      name: 'table-personnel',
      component: () => import('@/views/tables/Personnel.vue'),
      meta: {
        pageTitle: 'Personnel Table',
        breadcrumb: [
          {
            text: 'Personnel Table',
            active: true
          }
        ]
      }
    },
    {
      path: '/tables/personnel-group',
      name: 'table-personnel-group',
      component: () => import('@/views/tables/PersonnelGroup.vue'),
      meta: {
        pageTitle: 'Personnel Group Table',
        breadcrumb: [
          {
            text: 'Personnel Group Table',
            active: true
          }
        ]
      }
    },
    {
      path: '/tables/problem',
      name: 'table-problem',
      component: () => import('@/views/tables/Problem.vue'),
      meta: {
        pageTitle: 'Problem Table',
        breadcrumb: [
          {
            text: 'Problem Table',
            active: true
          }
        ]
      }
    },
    {
      path: '/tables/problem-type',
      name: 'table-problem-type',
      component: () => import('@/views/tables/ProblemType.vue'),
      meta: {
        pageTitle: 'Problem Type Table',
        breadcrumb: [
          {
            text: 'Problem Type Table',
            active: true
          }
        ]
      }
    },
    {
      path: '/tables/site',
      name: 'table-site',
      component: () => import('@/views/tables/Site.vue'),
      meta: {
        pageTitle: 'Site Table',
        breadcrumb: [
          {
            text: 'Site Table',
            active: true
          }
        ]
      }
    },
    {
      path: '/tables/unit',
      name: 'table-unit',
      component: () => import('@/views/tables/Unit.vue'),
      meta: {
        pageTitle: 'Unit Table',
        breadcrumb: [
          {
            text: 'Unit Table',
            active: true
          }
        ]
      }
    },
    {
      path: '/tables/user',
      name: 'table-user',
      component: () => import('@/views/tables/User.vue'),
      meta: {
        pageTitle: 'User Table',
        breadcrumb: [
          {
            text: 'User Table',
            active: true
          }
        ]
      }
    },
    {
      path: '/tables/package-detail',
      name: 'table-package-detail',
      component: () => import('@/views/tables/PackageDetail.vue'),
      meta: {
        pageTitle: 'Package Detail',
        breadcrumb: [
          {
            text: 'Package Detail',
            active: true
          }
        ]
      }
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true
          }
        ]
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
});

router.beforeEach((to, _from, next) => {
  const localStorageCheck = localStorage.token && localStorage.dbLanguage;

  if (to.name === 'login' && localStorageCheck) {
    router.push({ path: '/', query: { to: to.path } });
  }

  if (to.name !== 'login' && !localStorageCheck) {
    router.push({ path: '/login', query: { to: to.path } });
  }

  return next();
});

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
